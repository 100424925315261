import { useHistory } from 'react-router-dom'
import BusinessSidebar from '@components/sidebars/business.sidebar'
import useProducts from '@hooks/api/products/use-products.hook'
import BasicPageHeaderWithActions from '@components/headers/basic-page-with-actions.header'
import FullWidthMobileConstrainedLayoutContainer
    from '@components/layout-containers/full-width-mobile-constrained.layout-container'
import DefaultDashboardLayout from '@components/layouts/default-dashboard.layout'
import { useMemo } from 'react'
import StripedTable from '@components/tables/striped.table'

export default function ProductsIndex() {
    const history = useHistory()
    const { data: products } = useProducts()

    const rowData = useMemo(() => {
        return products?.map(product => ({
            id: product.name,
            rows: [
                {
                    text: product.name
                },
                {
                    text: product.unit
                },
                {
                    text: product.defaultPrice.toString()
                }
            ]
        })) ?? []
    }, [products])

    return (
        <DefaultDashboardLayout sidebar={<BusinessSidebar />}>
            <BasicPageHeaderWithActions title="Produsele tale" dark primaryButton={{ text: 'Creeaza un nou produs', action: () => history.push('/business/products/new')}}/>
            <FullWidthMobileConstrainedLayoutContainer>
                <StripedTable columns={['Nume produs', 'Unitate de măsură', 'Preț implicit']} rowData={rowData} />
            </FullWidthMobileConstrainedLayoutContainer>
        </DefaultDashboardLayout>
    )
}