import {ReactNode} from "react";

interface Props {
    children?: ReactNode
}

export default function FullWidthMobileConstrainedLayoutContainer({ children }: Props) {
    return (
        <div className="pt-4 max-w mx-auto sm:px-6 lg:px-8">
            {children}
        </div>
    )
}