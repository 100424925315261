import { Switch } from 'react-router-dom'
import RequireUnauthenticatedRoute from '@route-components/require-unauthenticated-route.component'
import LoginPage from '@routes/authentication/login/login.page'
import RegistrationPage from '@routes/authentication/registration/registration.page'


export default function AuthenticationRouter() {
    return (
        <Switch>
            <RequireUnauthenticatedRoute exact path="/auth/login" component={LoginPage} />
            <RequireUnauthenticatedRoute exact path="/auth/register" component={RegistrationPage} />
        </Switch>
    )
}