import FullWidthMobileConstrainedLayoutContainer from "../layout-containers/full-width-mobile-constrained.layout-container";

interface HeaderButtonProps {
    text: string
    action: () => void
}

interface HeaderProps {
    title: string
    dark?: boolean
    primaryButton?: HeaderButtonProps,
    secondaryButton?: HeaderButtonProps
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function BasicPageHeaderWithActions({ title, dark, primaryButton, secondaryButton }: HeaderProps) {
    return (
        <div className="bg-green-800 pb-6 pt-3">
            <FullWidthMobileConstrainedLayoutContainer>
                <div className="md:flex md:items-center md:justify-between">
                    <div className="flex-1 min-w-0">
                        <h2
                            className={classNames(
                                "text-2xl font-bold leading-7 sm:text-3xl sm:truncate",
                                dark ? 'text-white' : 'text-gray-900'
                            )}
                        >
                            { title }
                        </h2>
                    </div>
                    <div className="mt-4 flex md:mt-0 md:ml-4">
                        { secondaryButton !== undefined ? <HeaderButton {...secondaryButton} /> : <></> }
                        { primaryButton !== undefined ? <HeaderButton {...primaryButton} primary /> : <></> }
                    </div>
                </div>
            </FullWidthMobileConstrainedLayoutContainer>
        </div>
    )
}

function HeaderButton({ text, action, primary }: HeaderButtonProps & { primary?: boolean }) {
    return (
        <button
            type="button"
            className={classNames(
                "inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500",
                primary ? "ml-3 border-transparent text-white bg-green-600 hover:bg-green-700 focus:ring-green-500" : "border-gray-300 text-gray-700 bg-white hover:bg-gray-200"
            )}
            onClick={action}
        >
            { text }
        </button>
    )
}
