import { useContext, useState, useEffect } from 'react'
import { AxiosResponse, OpenAPIClientAxios, UnknownOperationMethod, AxiosError } from 'openapi-client-axios'
import {OpenAPIContext} from "../../providers/open-api-provider.component";

type OperationParameters = Parameters<UnknownOperationMethod>;

export function useOperation<T>(
    operationId: string,
    ...params: OperationParameters
): { loading: boolean; error?: Error; data?: T | undefined; response?: AxiosResponse; api: OpenAPIClientAxios } {
    const { api } = useContext(OpenAPIContext);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<AxiosError | undefined>(undefined);
    const [data, setData] = useState<any>(undefined);
    const [response, setResponse] = useState<AxiosResponse | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const client = await api.getClient();

            let res: AxiosResponse;
            try {
                res = await client[operationId](...params);
                setResponse(res);
                setData(res.data);
            } catch (err) {
                setError(err);
            }
            setLoading(false);
        })();

        // Runs only once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { loading, error, data, response, api };
}