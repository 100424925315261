import UserSidebar from '@components/sidebars/user.sidebar'
import Button from '@components/buttons/button.component'
import { removeToken } from '../../../store/slices/authentication.slice'
import { useDispatch } from 'react-redux'
import BasicPageHeaderWithActions from '@components/headers/basic-page-with-actions.header'
import FullWidthMobileConstrainedLayoutContainer
    from '@components/layout-containers/full-width-mobile-constrained.layout-container'
import DefaultDashboardLayout from '@components/layouts/default-dashboard.layout'


export default function UserSettingsPage() {
    const dispatch = useDispatch()

    return (
        <DefaultDashboardLayout sidebar={<UserSidebar />}>
            <BasicPageHeaderWithActions title="Setările contului tău" dark />
            <FullWidthMobileConstrainedLayoutContainer>
                <Button action={() => dispatch(removeToken())}>
                    Logout
                </Button>
            </FullWidthMobileConstrainedLayoutContainer>
        </DefaultDashboardLayout>
    )
}