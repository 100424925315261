import BusinessSidebar from '@components/sidebars/business.sidebar'
import useCurrentBusiness from '@hooks/api/business/use-current-business.hook'
import BasicPageHeaderWithActions from '@components/headers/basic-page-with-actions.header'
import FullWidthMobileConstrainedLayoutContainer
    from '@components/layout-containers/full-width-mobile-constrained.layout-container'
import DefaultDashboardLayout from '@components/layouts/default-dashboard.layout'


export default function BusinessDashboardIndex() {
    const { data } = useCurrentBusiness()

    return (
        <DefaultDashboardLayout sidebar={<BusinessSidebar />}>
            <BasicPageHeaderWithActions title={ data?.name ?? "" } dark />
            <FullWidthMobileConstrainedLayoutContainer>
                <h1>Hello</h1>
            </FullWidthMobileConstrainedLayoutContainer>
        </DefaultDashboardLayout>
    )
}