import { Disclosure } from '@headlessui/react'
import useCurrentUser from "@hooks/api/user/use-current-user.hook";
import { Link } from 'react-router-dom';
import NavigationItem from "@hooks/menus/navigation-item.interface";
import useCurrentBusiness from "@hooks/api/business/use-current-business.hook";
import gravatar from 'gravatar'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

interface DefaultSidebarProps {
    navigation: NavigationItem[]
}

export default function DefaultSidebar({ navigation }: DefaultSidebarProps) {
    return (
        <>
            <div className="flex flex-col flex-grow pt-5 pb-4 bg-green-600 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                    <img
                        className="h-8 w-auto"
                        src="/assets/img/logos/vitt_white.png"
                        alt="VITT"
                    />
                </div>
                <div className="mt-5 flex-grow flex flex-col">
                    <nav className="flex-1 px-2 space-y-1 bg-green-600" aria-label="Sidebar">
                        {navigation.map((item) =>
                            !item.children ? (
                                <div key={item.name}>
                                    <Link
                                        to={item.href ?? ''}
                                        className={classNames(
                                            item.current
                                                ? 'bg-green-800 text-white'
                                                : 'bg-green-600 text-white hover:bg-green-50 hover:text-green-900',
                                            'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? 'text-green-300' : 'text-green-400 group-hover:text-green-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                </div>
                            ) : (
                                <Disclosure as="div" key={item.name} className="space-y-1">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-green-100 text-white'
                                                        : 'bg-green-600 text-white hover:bg-green-50 hover:text-green-900',
                                                    'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-green-500'
                                                )}
                                            >
                                                <item.icon
                                                    className="mr-3 flex-shrink-0 h-6 w-6 text-white group-hover:text-green-500"
                                                    aria-hidden="true"
                                                />
                                                <span className="flex-1">{item.name}</span>
                                                <svg
                                                    className={classNames(
                                                        open ? 'text-green-400 rotate-90' : 'text-green-300',
                                                        'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-green-400 transition-colors ease-in-out duration-150'
                                                    )}
                                                    viewBox="0 0 20 20"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                </svg>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="space-y-1">
                                                {item.children?.map((subItem) => (
                                                    <Link
                                                        key={subItem.name}
                                                        to={subItem.href}
                                                        className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-200 rounded-md hover:text-green-900 hover:bg-green-50"
                                                    >
                                                        {subItem.name}
                                                    </Link>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            )
                        )}
                    </nav>
                </div>
            </div>
            <UserSection />
        </>
    )
}

function UserSection() {
    const { data: userData } = useCurrentUser()
    const { data: businessData } = useCurrentBusiness()

    return (
        <div className="flex-shrink-0 flex border-t border-green-800 p-4">
            <Link to="/user" className="flex-shrink-0 group block w-full">
                <div className="flex items-center">
                    <div className="flex-none">
                        <img
                            className="inline-block rounded-full h-10 w-10"
                            src={ userData?.email !== undefined ? gravatar.url(userData.email, {s: '80', r: 'pg' ,d: 'retro'}) : "" }
                            alt="Poza de profil"
                        />
                    </div>
                    <div className="ml-3 flex-grow-0 flex-shrink-1 grid">
                        <p className="font-medium text-white text-sm">
                            { userData?.fullName ?? userData?.email ?? "" }
                        </p>
                        <p className="font-medium text-green-200 group-hover:text-white text-xs truncate">
                            { businessData?.name.toUpperCase() ?? "" }
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    )
}