import React, { useEffect, useState } from "react";
import { Switch } from '@headlessui/react';
import { useFormContext } from "react-hook-form";
import classnames from "classnames";

interface ToggleInputProps {
    id: string
    title: string
    subtitle?: string
}

export default function ToggleInput(props: ToggleInputProps) {
    const { register, setValue, watch } = useFormContext()
    const idValue = watch(props.id, false)

    const [buttonClassName, setButtonClassName] = useState("")
    const [animationClassName, setAnimationClassName] = useState("")
    
    useEffect(() => {
        register(props.id)
        setValue(props.id, false)

        // Reasoning: useEffect as ComponentDidMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setButtonClassName(classnames({
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500': true,
            'bg-indigo-600': idValue,
            'bg-gray-200': !idValue
        }))

        setAnimationClassName(classnames({
            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200': true,
            'translate-x-5': idValue,
            'translate-x-0': !idValue
        }))
    }, [props.id, idValue])

    return (
        <div className="flex items-center justify-between">
            <span className="flex-grow flex flex-col" id="toggleLabel">
                <span className="text-sm font-medium text-gray-900">{ props.title }</span>
                { props.subtitle ? <span className="text-sm leading-normal text-gray-500">{ props.subtitle }</span> : <></> }
            </span>
            <Switch checked={idValue} onChange={checked => setValue(props.id, checked)} className={buttonClassName}>
                <span className={animationClassName} />
            </Switch>
        </div>
    )
}