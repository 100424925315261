import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AuthenticationState {
    accessToken?: string,
    businessId: string
}

const initialState: AuthenticationState = { accessToken: undefined, businessId: '' }

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<string>) {
            state.accessToken = action.payload
            state.businessId = ''
        },
        setBusinessId(state, action: PayloadAction<string>) {
            state.businessId = action.payload
        },
        removeToken(state) {
            state.accessToken = undefined
            state.businessId = ''
        }
    }
})

export const { setToken, setBusinessId, removeToken } = authenticationSlice.actions
export default authenticationSlice.reducer