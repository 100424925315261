import { OpenAPIClientAxios } from "openapi-client-axios";
import React, {createContext, ReactNode, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {removeToken} from "../../store/slices/authentication.slice";

// @ts-ignore
export const OpenAPIContext: React.Context<{
    api: OpenAPIClientAxios
}> = createContext({ api: undefined })

interface Props {
    children?: ReactNode
}

export const OpenAPIProvider = ({ children }: Props) => {
    const accessToken = useSelector((state: RootState) => state.authentication.accessToken)
    const businessId = useSelector((state: RootState) => state.authentication.businessId)
    const dispatch = useDispatch()

    const api = useMemo(() => {
        const client = new OpenAPIClientAxios({
            definition: process.env.REACT_APP_OPENAPI_DEFINITION ?? "",
            axiosConfigDefaults: {
                headers: {
                    "Authorization": accessToken !== undefined ? `Bearer ${accessToken}` : undefined,
                    "X-VITT-BUSINESS-ID": businessId !== '' ? businessId : undefined
                },
                validateStatus: status => {
                    if (status === 200) {
                        return true
                    } else if (status === 401) {
                        dispatch(removeToken())
                    }

                    return false
                }
            }
        })
        try {
            client.initSync()
        } catch(err) {}
        return client
    }, [dispatch, accessToken, businessId])

    return <OpenAPIContext.Provider value={{ api }}>{children}</OpenAPIContext.Provider>
}