import BusinessSidebar from '@components/sidebars/business.sidebar'
import BasicPanel from '@components/panels/basic.panel'
import BasicPageHeaderWithActions from '@components/headers/basic-page-with-actions.header'
import FullWidthMobileConstrainedLayoutContainer
    from '@components/layout-containers/full-width-mobile-constrained.layout-container'
import DefaultDashboardLayout from '@components/layouts/default-dashboard.layout'
import { useForm } from 'react-hook-form'
import { useOperationMethod } from '@hooks/api/use-operation-method.hook'
import Form from '@components/forms/form.component'
import TextFieldInput from '@components/forms/inputs/text-field.input'
import Button from '@components/buttons/button.component'
import { useHistory } from 'react-router-dom'

interface CreateProductInput {
    name: string
    unit: string
    defaultPrice: number
}

export default function NewProductPage() {
    const history = useHistory()

    const methods = useForm<CreateProductInput>()
    const [createProduct] = useOperationMethod('createProduct')

    const onSuccess = async (data: CreateProductInput) => {
        await createProduct(undefined, {
            ...data
        })

        history.push('/business/products')
    }

    const onError = (error: any) => {
        console.log(error)
    }

    return (
        <DefaultDashboardLayout sidebar={<BusinessSidebar />}>
            <BasicPageHeaderWithActions title="Creeaza un nou produs" dark />
            <FullWidthMobileConstrainedLayoutContainer>
                <BasicPanel>
                    <Form methods={methods} onSubmit={onSuccess} onError={onError}>
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <TextFieldInput label="Numele produsului" id="name" />
                            <TextFieldInput label="Unitate de măsură" id="unit" />
                            <TextFieldInput label="Preț implicit" id="defaultPrice" />
                            <Button type="submit">Creează</Button>
                        </div>
                    </Form>
                </BasicPanel>
            </FullWidthMobileConstrainedLayoutContainer>
        </DefaultDashboardLayout>
    )
}