import { useHistory } from 'react-router-dom'
import ButtonLink from '@components/links/button.link'
import UserSidebar from '@components/sidebars/user.sidebar'
import useCurrentBusinesses from '@hooks/api/business/use-current-businesses.hook'
import { setBusinessId } from '../../../store/slices/authentication.slice'
import SpinnerWell from '@components/wells/spinner.well'
import { BusinessView } from '@hooks/api/interfaces/business-view.interface'
import { useDispatch } from 'react-redux'
import BasicPageHeaderWithActions from '@components/headers/basic-page-with-actions.header'
import FullWidthMobileConstrainedLayoutContainer
    from '@components/layout-containers/full-width-mobile-constrained.layout-container'
import DefaultDashboardLayout from '@components/layouts/default-dashboard.layout'

export default function UserDashboardIndex() {
    const { loading, data } = useCurrentBusinesses()

    return (
        <DefaultDashboardLayout sidebar={<UserSidebar />}>
                <BasicPageHeaderWithActions title="Selectează o firmă" dark />
                <FullWidthMobileConstrainedLayoutContainer>
                    <div className="grid grid-cols-1 gap-y-4">
                        { loading ? <SpinnerWell text="Se încarcă lista firmelor la care ai acces..." /> : '' }
                        { data?.map(businessData => <BusinessItem key={businessData.slug} {...businessData} />) }
                        <div className="bg-white overflow-hidden shadow sm:rounded-lg w-full">
                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-4">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Adaugă o nouă firmă
                                        </h3>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Adaugă-ți firma în aplicația VITT și începe să utilizezi tehnologiile noastre care-ți vor revoluționa afacerea.
                                        </p>
                                    </div>
                                    <div className="ml-4 mt-4 flex-shrink-0">
                                        <ButtonLink type="button"
                                                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                to="/user/create-business">

                                            Adaugă firmă
                                        </ButtonLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FullWidthMobileConstrainedLayoutContainer>
        </DefaultDashboardLayout>
    )
}

function BusinessItem(business: BusinessView) {
    const dispatch = useDispatch()
    const history = useHistory()

    const switchCompany = (slug: string) => {
        dispatch(setBusinessId(slug))
        history.push(`/business/dashboard`)
    }

    return (
        <div className="bg-white overflow-hidden shadow sm:rounded-lg w-full" key={business.slug}>
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            { business.name }
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            { `${business.vatPayer ? 'RO' : ''}${business?.fiscalCode} | ${business?.registrationNumber}` }
                        </p>
                    </div>
                    <div className="ml-4 mt-4 flex-shrink-0">
                        <button type="button"
                                className="relative disabled:cursor-wait disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                onClick={() => switchCompany(business.slug)}
                        >
                            Intră în aplicație
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}