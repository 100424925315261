import BusinessSidebar from '@components/sidebars/business.sidebar'
import BasicPageHeaderWithActions from '@components/headers/basic-page-with-actions.header'
import DefaultDashboardLayout from '@components/layouts/default-dashboard.layout'

export default function StocksIndex() {
    return (
        <DefaultDashboardLayout sidebar={<BusinessSidebar />}>
            <BasicPageHeaderWithActions title="Stocurile tale" dark />

        </DefaultDashboardLayout>
    )
}