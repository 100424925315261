import React, {ReactNode} from "react";
import {useHistory} from "react-router-dom";

interface ButtonLinkProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    to: string
    children?: ReactNode
}

export default function ButtonLink({ to, children, ...rest }: ButtonLinkProps) {
    const history = useHistory()

    return (
        <button {...rest} onClick={() => history.push(to)}>
            {children}
        </button>
    )
}