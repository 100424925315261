import {FormProvider, UseFormReturn} from "react-hook-form";
import {ReactNode} from "react";

interface FormProps {
    methods: UseFormReturn<any>
    onSubmit: (data: any, event: any) => void
    onError: (error: any, event: any) => void
    loading?: boolean
    children?: ReactNode
}

export default function Form({ methods, onSubmit, onError, children, loading }: FormProps) {
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
                { children }
            </form>
        </FormProvider>
    )
}