import { useHistory } from 'react-router-dom'
import NavigationItem from '@hooks/menus/navigation-item.interface'
import { CogIcon, HomeIcon } from '@heroicons/react/outline'

export default function useUserNavigationMenu(): { navigation: NavigationItem[] } {
    const history = useHistory()

    const isPath = (path: string): boolean => {
        return history.location.pathname.startsWith(path)
    }

    return {
        navigation: [
            {
                name: 'Firmele tale',
                icon: HomeIcon,
                href: '/user',
                current: isPath('/user')
            },
            {
                name: 'Setările contului',
                icon: CogIcon,
                href: '/user/settings',
                current: isPath('/user/settings')
            }
        ]
    }
}