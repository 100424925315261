import {useFormContext} from "react-hook-form";

interface TextFieldInputProps {
    label: string
    id: string
    placeholder?: string
}

export default function TextFieldInput({ id, label, placeholder }: TextFieldInputProps) {
    const { register } = useFormContext()

    return (
        <div>
            <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                { label }
            </label>
            <div className="mt-1">
                <input
                    type="text"
                    id={id}
                    autoComplete={id}
                    placeholder={placeholder}
                    className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register(id)}
                />
            </div>
        </div>
    )
}