import {FormProvider, useForm} from "react-hook-form";
import { useDispatch } from "react-redux";
import {useOperationMethod} from "@hooks/api/use-operation-method.hook";
import {setToken} from "../../../store/slices/authentication.slice";
import {Link, useHistory} from "react-router-dom";

interface LoginInput {
    email: string
    password: string
}

export default function LoginPage() {
    const dispatch = useDispatch()
    const history = useHistory()
    const methods = useForm<LoginInput>()

    const [authenticate, { loading, error }] = useOperationMethod('authenticate')

    const onSubmit = async (data: LoginInput) => {
        const response = await authenticate({}, data)
        dispatch(setToken(response.data.access_token))
        history.push('/user')
    }

    const onError = (data: any) => {
        console.log(data)
    }

    return (
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src="/assets/img/logos/vitt_black.png"
                            alt="VITT"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Intră în contul tău</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            Sau{' '}
                            <Link to="/auth/register" className="font-medium text-green-600 hover:text-green-500">
                                începe perioada ta gratuită de încercare.
                            </Link>
                        </p>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit, onError)} className="space-y-6">
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Adresă de email
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                                {...methods.register("email")}
                                            />
                                        </div>
                                    </div>

                                    <div className="space-y-1">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Parolă
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                                {...methods.register("password")}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <input
                                                id="remember_me"
                                                name="remember_me"
                                                type="checkbox"
                                                className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                                                Ține-mă minte
                                            </label>
                                        </div>

                                        <div className="text-sm">
                                            <Link to="#" className="font-medium text-green-600 hover:text-green-500">
                                                Ți-ai uitat parola?
                                            </Link>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                        >
                                            Intră în cont
                                        </button>
                                    </div>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1497366811353-6870744d04b2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                    alt=""
                />
            </div>
        </div>
    )
}