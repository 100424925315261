interface WellProps {
    children: any
}

export default function Well(props: WellProps) {
    return (
        <div className="bg-gray-200 overflow-hidden shadow-inner rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                { props.children }
            </div>
        </div>
    )
}