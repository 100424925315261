import React from 'react'
import ReactDOM from 'react-dom'
import './assets/stylesheets/tailwind.css'
import reportWebVitals from './reportWebVitals'
import { getStoreAndPersistor } from './store/store'
import Router from '@routes/router.component'
import { OpenAPIProvider } from './components/providers/open-api-provider.component'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'

export const { store, persistor } = getStoreAndPersistor()

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<h1>Loading persisted state...</h1>}>
            <OpenAPIProvider>
                <Router />
            </OpenAPIProvider>
        </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
