import { useHistory } from 'react-router-dom'
import NavigationItem from '@hooks/menus/navigation-item.interface'
import { BriefcaseIcon, DeviceMobileIcon, HomeIcon } from '@heroicons/react/outline'

export default function useBusinessNavigationMenu(): { navigation: NavigationItem[] } {
    const history = useHistory()

    const isPath = (path: string): boolean => {
        return history.location.pathname.startsWith(path)
    }

    return {
        navigation: [
            {
                name: 'Vedere de ansamblu',
                icon: HomeIcon,
                href: '/business/dashboard',
                current: isPath('/business/dashboard')
            },
            {
                name: 'Stocuri',
                icon: BriefcaseIcon,
                href: '/business/stocks',
                current: isPath('/business/stocks')
            },
            {
                name: 'Produse',
                icon: DeviceMobileIcon,
                href: '/business/products',
                current: isPath('/business/products')
            }
        ]
    }
}