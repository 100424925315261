import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Redirect} from "react-router-dom";

export default function AppIndex() {
    const isAuthenticated = useSelector((state: RootState) => state.authentication.accessToken !== undefined)

    if (isAuthenticated) {
        return (
            <Redirect to="/user" />
        )
    } else {
        return (
            <Redirect to="/auth/login" />
        )
    }
}