import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AppIndex from '@routes/index/app.index'
import AuthenticationRouter from '@routes/authentication/authentication.router'
import UserDashboardRouter from '@routes/user-dashboard/user-dashboard.router'
import BusinessDashboardRouter from '@routes/business-dashboard/business-dashboard.router'

export default function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={AppIndex} />
                <Route path="/auth" component={AuthenticationRouter} />
                <Route path="/user" component={UserDashboardRouter} />
                <Route path="/business" component={BusinessDashboardRouter} />

            </Switch>
        </BrowserRouter>
    )
}