import Spinner from '@components/spinners/spinner.component'
import Well from '@components/wells/well.component'

interface SpinnerWellProps {
    text: string
}

export default function SpinnerWell({ text }: SpinnerWellProps) {
    return (
        <Well>
            <div className="flex items-center justify-center">
                <Spinner extraClasses="h-5 w-5 text-green-500 text-center" />
                <span className="pl-2 font-bold text-gray-700">{ text }</span>
            </div>
        </Well>
    )
}
