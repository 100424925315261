import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TenantState {
    tenant_id?: string
    confirmed: boolean
    invalid: boolean
}

const initialState: TenantState = { tenant_id: undefined, confirmed: false, invalid: false }

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        set(state, action: PayloadAction<string>) {
            state.tenant_id = action.payload
            state.confirmed = false
            state.invalid = false
        },
        confirmAuthorization(state) {
            state.confirmed = true
            state.invalid = false
        },
        declineAuthorization(state) {
            state.confirmed = false
            state.invalid = true
        }
    },
})

export const { set, confirmAuthorization, declineAuthorization } = tenantSlice.actions
export default tenantSlice.reducer