import { Switch } from "react-router-dom";
import ProtectedRoute from '@route-components/protected-route.component'
import UserDashboardIndex from '@routes/user-dashboard/index/user-dashboard.index'
import UserSettingsPage from '@routes/user-dashboard/settings/user-settings.page'
import CreateBusinessPage from '@routes/user-dashboard/business/create-business.page'


export default function UserDashboardRouter() {
    return (
        <Switch>
            <ProtectedRoute exact path="/user" component={UserDashboardIndex} />
            <ProtectedRoute exact path="/user/settings" component={UserSettingsPage} />
            <ProtectedRoute exact path="/user/create-business" component={CreateBusinessPage} />
        </Switch>
    )
}