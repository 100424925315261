import {ReactNode} from "react";

interface BasicPanelProps {
    children?: ReactNode
    className?: string
}

export default function BasicPanel({ children, className }: BasicPanelProps) {
    return (
        <div className={"bg-white overflow-hidden shadow rounded-lg " + className}>
            <div className="px-4 py-5 sm:p-6">
                { children }
            </div>
        </div>
    )
}