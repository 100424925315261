import {Redirect, Route, RouteComponentProps, RouteProps} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

interface BusinessProtectedRouteProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

export default function BusinessProtectedRoute({ component, ...args }: BusinessProtectedRouteProps) {
    const authenticated = useSelector((state: RootState) => state.authentication.accessToken !== undefined)
    const hasBusinessSelected = useSelector((state: RootState) => state.authentication.businessId !== '')

    if (!authenticated) {
        return (
            <Redirect to="/auth/login" />
        )
    } else if (authenticated && !hasBusinessSelected) {
        return (
            <Redirect to="/user"/>
        )
    } else {
        return (
            <Route component={(component)} {...args} />
        )
    }
}