import BasicPanel from '@components/panels/basic.panel'
import ToggleInput from '@components/forms/inputs/toggle.input'
import UserSidebar from '@components/sidebars/user.sidebar'
import TextFieldInput from '@components/forms/inputs/text-field.input'
import Form from '@components/forms/form.component'
import slugify from 'slugify'
import BasicPageHeaderWithActions from '@components/headers/basic-page-with-actions.header'
import DefaultDashboardLayout from '@components/layouts/default-dashboard.layout'
import { useForm } from 'react-hook-form'
import { useOperationMethod } from '@hooks/api/use-operation-method.hook'
import Button from '@components/buttons/button.component'
import { useEffect } from 'react'
import FullWidthMobileConstrainedLayoutContainer
    from '@components/layout-containers/full-width-mobile-constrained.layout-container'
import { useHistory } from 'react-router-dom'

interface CreateBusinessInput {
    name: string
    fiscalCode: string
    registrationNumber: string
    slug: string
    address?: string
    region?: string
    postalCode?: string
    phoneNumber?: string
    vatPayer: boolean
}

export default function CreateBusinessPage() {
    const history = useHistory()

    const methods = useForm<CreateBusinessInput>()
    const [createBusiness] = useOperationMethod('createBusiness')

    const fiscalCode = methods.watch('fiscalCode', '')
    const name = methods.watch('name', '')

    useEffect(() => {
        methods.setValue('slug', slugify(name, {lower: true, remove: /[*+~.()'"!:@]/g}))
    }, [methods, name])

    const onSuccess = async (data: CreateBusinessInput) => {
        await createBusiness(undefined, {
            ...data
        })

        history.push('/user')
    }

    const onError = (error: any) => {
        console.log(error)
    }

    return (
        <DefaultDashboardLayout sidebar={<UserSidebar />}>
            <BasicPageHeaderWithActions title="Creează o firmă în aplicație" dark />
            <FullWidthMobileConstrainedLayoutContainer>
                <BasicPanel>
                    <Form methods={methods} onSubmit={onSuccess} onError={onError}>
                        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <TextFieldInput label="Numele firmei" id="name" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <TextFieldInput label="Cod fiscal" id="fiscalCode" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <TextFieldInput label="Nr ONRC" id="registrationNumber" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <TextFieldInput label="Identificator unic" id="slug" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <TextFieldInput label="Adresa" id="address" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <TextFieldInput label="Judet / Regiune" id="region" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <TextFieldInput label="Cod postal" id="postalCode" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <TextFieldInput label="Numar de telefon" id="phoneNumber" placeholder="e.g. ION POPESCU TRADE SRL" />
                            <ToggleInput title="Platitor de TVA" subtitle="Statut de platitor de TVA" id="vatPayer" />
                            <Button type="submit">Creează</Button>
                        </div>
                    </Form>
                </BasicPanel>
            </FullWidthMobileConstrainedLayoutContainer>
        </DefaultDashboardLayout>
    )
}