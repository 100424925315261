interface StripedTableProps {
    columns: string[],
    rowData: {
        id: string,
        rows: {
            text: string,
            text_classes?: string
        }[]
    }[],
    actionText?: string,
    action?: ((id: string) => void)
}

export default function StripedTable(props: StripedTableProps) {
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                { props.columns.map(columnTitle => <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" key={columnTitle}>{ columnTitle }</th>)}
                                { props.action ? <th scope="col" className="relative px-6 py-3"><span className="sr-only">Actions</span></th> : <></> }
                            </tr>
                            </thead>
                            <tbody>
                            { props.rowData.map((row, index) => (
                                <tr className={(index % 2 === 0) ? 'bg-white' : 'bg-gray-50'} key={row.id}>
                                    { row.rows.map((rowTextData, columnIndex) => (
                                        <td className={`px-6 py-4 whitespace-nowrap text-sm ${columnIndex === 0 ? 'font-medium' : ''} text-gray-900 ${rowTextData.text_classes ?? ''}`} key={rowTextData.text + row.id}>
                                            { rowTextData.text }
                                        </td>
                                    )) }
                                    { props.action ? <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => props.action ? props.action(row.id) : ''}>{ props.actionText ?? '' }</button>
                                    </td>: <></> }
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}