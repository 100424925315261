import { Switch } from 'react-router-dom'
import BusinessProtectedRoute from '@route-components/business-protected-route.component'
import BusinessDashboardIndex from '@routes/business-dashboard/index/business-dashboard.index'
import StocksIndex from '@routes/business-dashboard/stocks/stocks.index'
import ProductsIndex from '@routes/business-dashboard/product/products.index'
import NewProductPage from '@routes/business-dashboard/product/new-product.page'

export default function BusinessDashboardRouter() {
    return (
        <Switch>
            <BusinessProtectedRoute exact path="/business/dashboard" component={BusinessDashboardIndex} />
            <BusinessProtectedRoute exact path="/business/stocks" component={StocksIndex} />
            <BusinessProtectedRoute exact path="/business/products" component={ProductsIndex} />
            <BusinessProtectedRoute exact path="/business/products/new" component={NewProductPage} />
        </Switch>
    )
}